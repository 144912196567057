<template>
  <div>
    <cbs-objects-tbl />
  </div>
</template>

<script>
import useCubus from '@/cubus/services/useCubus'
import CbsObjectsTbl from '@/cubus/components/objects/CbsObjectsTbl.vue'

export default {
  components: {
    CbsObjectsTbl,
  },
  data() {
    return {}
  },
  computed: {},
  created() {
    useCubus.setPageTitle('Objects')
  },
  methods: {},
}
</script>

<style scoped>
</style>
